import { createPopper } from '@popperjs/core';
import { element, getjQuery, typeCheckConfig } from './mdb/util/index';
import Data from './mdb/dom/data';
import Manipulator from './mdb/dom/manipulator';
import EventHandler from './mdb/dom/event-handler';
import SelectorEngine from './mdb/dom/selector-engine';
import Touch from './touch/index';
import {
  parseToHTML,
  getElementCenter,
  getEventCoordinates,
  getVector,
  getDisplacement,
} from './util';
import MAPS from './maps';

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'vectorMap';
const DATA_KEY = 'mdb.vectorMap';

const CLASSNAME_VECTOR_MAP = 'vector-map';
const CLASSNAME_TOOLTIP = 'vector-map-tooltip';
const CLASSNAME_SHOW = 'show';
const CLASSNAME_DRAGGED = 'vector-map-dragged';

const SELECTOR_VECTOR_MAP = '.vector-map';
const SELECTOR_ZOOM_IN_BTN = '.zoom-in';
const SELECTOR_ZOOM_OUT_BTN = '.zoom-out';

const DEFAULT_OPTIONS = {
  btnClass: 'btn-dark',
  colorMap: [],
  fill: '#E0E0E0',
  fillOpacity: 1,
  hover: true,
  hoverFill: '#BDBDBD',
  map: 'world',
  readonly: false,
  scale: 1,
  selectFill: '#B23CFD',
  selectRegion: null,
  stroke: '#000',
  strokeLinejoin: 'round',
  strokeOpacity: 1,
  strokeWidth: 0.5,
  tooltips: true,
};

const OPTIONS_TYPE = {
  btnClass: 'string',
  colorMap: 'array',
  fill: 'string',
  fillOpacity: 'number',
  hover: 'boolean',
  hoverFill: 'string',
  map: 'string',
  readonly: 'boolean',
  stroke: 'string',
  strokeOpacity: 'number',
  scale: 'number',
  selectFill: 'string',
  selectRegion: '(string|null)',
  strokeLinejoin: 'string',
  strokeWidth: 'number',
  tooltips: 'boolean',
};

const SVG_OPTIONS = [
  { property: 'fill', key: 'fill' },
  { property: 'fill-opacity', key: 'fillOpacity' },
  { property: 'stroke', key: 'stroke' },
  { property: 'stroke-opacity', key: 'strokeOpacity' },
  { property: 'stroke-width', key: 'strokeWidth' },
  { property: 'stroke-linejoin', key: 'strokeLinejoin' },
];

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class VectorMap {
  constructor(element, options = {}) {
    this._element = element;
    this._options = this._getConfig(options);

    this._svgMap = null;
    this._mapUnits = [];
    this._selection = null;

    // Tooltips
    this._popper = null;
    this._tooltip = null;
    this._virtualElement = null;

    // Position
    this._x = 0;
    this._y = 0;
    this._left = 0;
    this._top = 0;
    this._prevPosition = null;

    this._viewportCenter = { x: 0, y: 0 };
    this._initialTranslation = { x: 0, y: 0 };

    this._origin = { x: 0, y: 0 };

    // Scale
    this._scale = this._options.scale;
    this._scaleFactor = 0.5;
    this._zoomInBtn = null;
    this._zoomOutBtn = null;
    this._vector = null;

    this._mousedownHandler = (e) => this._handleDragstart(e);
    this._mousemoveHandler = (e) => this._handleDrag(e);
    this._mouseupHandler = () => this._handleDragend();

    this._touchstartHandler = (e) => this._handleTouchstart(e);
    this._touchmoveHandler = (e) => this._handleTouchmove(e);
    this._touchendHandler = (e) => this._handleTouchend(e);

    if (this._element) {
      Data.setData(element, DATA_KEY, this);

      this._setup();
    }
  }

  get selectedUnit() {
    return this._mapUnits[this._selection];
  }

  get hoverEvents() {
    return this._options.hover || this._options.tooltips;
  }

  get dragging() {
    return this._prevPosition !== null;
  }

  get mapRect() {
    return this._svgMap.getBoundingClientRect();
  }

  get mapElementRect() {
    return this._element.getBoundingClientRect();
  }

  get mapCenter() {
    return {
      x: this.mapRect.width / 2,
      y: this.mapRect.height / 2,
    };
  }

  // Public
  dispose() {
    if (this._element) {
      Data.removeData(this._element, DATA_KEY);

      this._removeEventHandlers();

      if (this._popper) {
        this._popper.destroy();
      }
    }
    this._element = null;
  }

  select(region) {
    let index = null;

    const unit = this._mapUnits.find((unit, i) => {
      if (unit.id === region) {
        index = i;
        return true;
      }

      return false;
    });

    if (unit) {
      this._selectUnit(unit, index);
    }
  }

  // Private

  _getConfig(options) {
    const config = {
      ...DEFAULT_OPTIONS,
      ...Manipulator.getDataAttributes(this._element),
      ...options,
    };

    typeCheckConfig(NAME, config, OPTIONS_TYPE);

    return config;
  }

  _setup() {
    Manipulator.addClass(this._element, CLASSNAME_VECTOR_MAP);

    this._renderMap();

    this._setupSVGProperties();

    this._setupToolbar();

    this._setupMapPosition();

    this._setupMapUnits();

    this._setupEventListeners();

    this._setupTouch();

    if (this._options.tooltips) {
      this._setupTooltips();
    }
  }

  _selectUnit(unit, index) {
    if (this._selection !== null) {
      this.selectedUnit.selected = false;

      if (this.selectedUnit.fill) {
        this.selectedUnit.element.setAttribute('fill', this.selectedUnit.fill);
      } else {
        this.selectedUnit.element.removeAttribute('fill');
      }
    }

    if (this._selection === index) {
      this._selection = null;
    } else {
      this._selection = index;
      this.selectedUnit.selected = true;
      unit.element.setAttribute('fill', this._options.selectFill);
    }

    EventHandler.trigger(this._element, 'select', { selected: this.selectedUnit });
  }

  _renderMap() {
    this._svgMap = SelectorEngine.findOne('svg', this._element);

    if (!this._svgMap) {
      this._svgMap = this._getSVGMap();
      this._element.appendChild(this._svgMap);
    }
  }

  _setupToolbar() {
    const toolbar = this._getToolbar();

    this._element.appendChild(toolbar);

    this._zoomInBtn = SelectorEngine.findOne(SELECTOR_ZOOM_IN_BTN, this._element);
    this._zoomOutBtn = SelectorEngine.findOne(SELECTOR_ZOOM_OUT_BTN, this._element);

    this._toggleZoomOutBtn();
  }

  _setupMapPosition() {
    this._viewportCenter = getElementCenter(this.mapElementRect);

    this._initialTranslation = this._getInitialTranslation();

    this._origin = getElementCenter(this.mapRect);

    this._updateTransformOrigin();

    this._setInitialMapPosition();

    this._updateMapTransform();
  }

  _setupMapUnits() {
    this._mapUnits = this._getMapUnits();

    this._setUnitsData();

    this._mapUnits.forEach((unit, index) => {
      if (this._options.selectRegion === unit.id) {
        this._selectUnit(unit, index);
      } else if (unit.fill) {
        unit.element.setAttribute('fill', unit.fill);
      }
    });
  }

  _setupTouch() {
    this._pinch = new Touch(this._svgMap, 'pinch');
    this._pinch.init();

    this._tap = new Touch(this._element, 'tap', { taps: 2, interval: 500 });
    this._tap.init();
  }

  _getSVGMap() {
    const map = MAPS[this._options.map];

    if (!map) {
      throw new TypeError(`Map "${this._options.map}" is not available`);
    }

    const mapNode = SelectorEngine.findOne('svg', parseToHTML(map));

    return mapNode;
  }

  _getMapUnits() {
    return SelectorEngine.find('path', this._svgMap).map((path) => {
      const id = path.getAttribute('id');
      const title = path.getAttribute('title') || path.getAttribute('name');
      const d = path.getAttribute('d');

      return {
        element: path,
        d,
        id,
        title,
        selected: false,
      };
    });
  }

  _setUnitsData() {
    this._options.colorMap.forEach((settings) => {
      settings.regions.forEach((region) => {
        const tooltip = region.tooltip || '';
        const unit = this._mapUnits.find((unit) => {
          if (typeof region === 'string') {
            return unit.id === region;
          }

          return unit.id === region.id;
        });

        if (!unit) return;

        unit.fill = settings.fill;
        unit.tooltip = tooltip;
      });
    });
  }

  _setupSVGProperties() {
    const svgStyle = {};

    SVG_OPTIONS.forEach((option) => {
      svgStyle[option.property] = this._options[option.key];
    });

    Manipulator.style(this._svgMap, svgStyle);
  }

  _getInitialTranslation() {
    const mapCenter = getElementCenter(this.mapRect);
    return {
      x: this._viewportCenter.x - mapCenter.x,
      y: this._viewportCenter.y - mapCenter.y,
    };
  }

  _setInitialMapPosition() {
    this._x = this._initialTranslation.x;
    this._y = this._initialTranslation.y;
  }

  _setupEventListeners() {
    this._mapUnits.forEach((unit, i) => {
      if (!this._options.readonly) {
        EventHandler.on(unit.element, 'click', () => this._selectUnit(unit, i));
      }

      if (this.hoverEvents) {
        EventHandler.on(unit.element, 'mouseover', () => this._handleUnitMouseover(unit));
        EventHandler.on(unit.element, 'mouseout', () => this._handleUnitMouseout(unit));
      }
    });

    // Dragging
    EventHandler.on(this._svgMap, 'mousedown', this._mousedownHandler);
    EventHandler.on(window, 'mousemove', this._mousemoveHandler);
    EventHandler.on(window, 'mouseup', this._mouseupHandler);

    // Dragging - touch
    EventHandler.on(this._element, 'touchstart', this._mousedownHandler);
    window.addEventListener('touchmove', this._mousemoveHandler, { passive: false }); // Event listener has to be passive to prevent scrolling
    EventHandler.on(window, 'touchend', this._mouseupHandler);

    // Zoom

    EventHandler.on(this._zoomInBtn, 'click', () => this._zoom(this._scaleFactor));
    EventHandler.on(this._zoomOutBtn, 'click', () => this._zoom(-1 * this._scaleFactor));

    EventHandler.on(this._element, 'wheel', (e) => this._handleWheel(e));

    EventHandler.on(this._svgMap, 'pinch', (e) => {
      this._origin = this._calculateOrigin(e.origin);
      this._updateTransformOrigin();

      const factor = this._scale * (e.ratio - 1);

      this._zoom(factor);
    });

    EventHandler.on(this._element, 'tap', (e) => {
      this._origin = this._calculateOrigin(e.origin);
      this._updateTransformOrigin();

      let factor = 2;

      if (this._scale > this._options.scale) {
        factor = this._options.scale - this._scale;
      }

      this._zoom(factor);
    });
  }

  _setupTooltips() {
    this._tooltip = element('div');
    Manipulator.addClass(this._tooltip, CLASSNAME_TOOLTIP);
    this._element.appendChild(this._tooltip);

    this._virtualElement = {
      getBoundingClientRect: this._generateGetBoundingClientRect(),
    };

    this._popper = createPopper(this._virtualElement, this._tooltip);
  }

  _getToolbar() {
    const toolbar = element('div');

    Manipulator.addClass(toolbar, 'vector-map-toolbar');

    toolbar.innerHTML = `
    <button class="btn btn-floating ${this._options.btnClass} zoom-in"><i class="fa fa-plus"></i></button>
    <button class="btn btn-floating ${this._options.btnClass} zoom-out"><i class="fa fa-minus"></i></button>`;

    return toolbar;
  }

  _handleUnitMouseover(unit) {
    if (this.dragging) {
      return;
    }

    if (this._options.hover) {
      unit.element.setAttribute('fill', this._options.hoverFill);
    }

    if (this._options.tooltips) {
      this._showTooltip(unit);
    }
  }

  _handleUnitMouseout(unit) {
    if (this._options.hover) {
      if (unit.selected) {
        unit.element.setAttribute('fill', this._options.selectFill);
      } else if (unit.fill) {
        unit.element.setAttribute('fill', unit.fill);
      } else {
        unit.element.removeAttribute('fill');
      }
    }

    if (this._options.tooltips) {
      this._hideTooltip();
    }
  }

  _handleDragstart(event) {
    this._prevPosition = getEventCoordinates(event);
    Manipulator.addClass(this._element, CLASSNAME_DRAGGED);
  }

  _handleDrag(event) {
    if (!this._prevPosition) return;

    event.preventDefault();

    const mousePosition = getEventCoordinates(event);
    const displacement = this._getValueInMapBoundry(
      getDisplacement(mousePosition, this._prevPosition)
    );

    this._x = displacement.x;
    this._y = displacement.y;

    this._prevPosition = mousePosition;

    this._updateMapTransform();
  }

  _handleDragend() {
    this._prevPosition = null;

    Manipulator.removeClass(this._element, CLASSNAME_DRAGGED);
  }

  _handleTouchstart(e) {
    if (e.touches.length > 1) {
      this._vector = getVector(e);

      this._origin = { ...this._vector.center };
      this._updateTransformOrigin();
      return;
    }

    this._handleDragstart(e);
  }

  _handleTouchmove(e) {
    if (e.touches.length > 1 && this._vector) {
      e.preventDefault();
      e.stopPropagation();

      const vector = getVector(e);

      const ratio = vector.length / this._vector.length;
      const scaleFactor = this._scale * (ratio - 1);

      this._zoom(scaleFactor);

      this._vector = vector;
      return;
    }
    this._handleDrag(e);
  }

  _handleTouchend(e) {
    if (e.touches.length > 1) {
      this._vector = null;
      return;
    }
    this._handleDragend();
  }

  _handleWheel(event) {
    event.preventDefault();

    const mousePosition = getEventCoordinates(event);

    this._origin = this._calculateOrigin(mousePosition);

    this._updateTransformOrigin();

    const factor = (event.deltaY / Math.abs(event.deltaY)) * -1 * this._scaleFactor;

    this._zoom(factor);
  }

  _getValueInMapBoundry({ x, y }) {
    const margins = this._getMapMargins();

    let xPosition = this._x;
    let yPosition = this._y;

    if ((x < 0 && margins.right > 0) || (x > 0 && margins.left > 0)) {
      xPosition += x;
    }

    if ((y > 0 && margins.top > 0) || (y < 0 && margins.bottom > 0)) {
      yPosition += y;
    }

    return {
      x: xPosition,
      y: yPosition,
    };
  }

  _getMapMargins() {
    return {
      left: this.mapElementRect.left - this.mapRect.left,
      top: this.mapElementRect.top - this.mapRect.top,
      right: this.mapRect.right - this.mapElementRect.right,
      bottom: this.mapRect.bottom - this.mapElementRect.bottom,
    };
  }

  _zoom(factor) {
    const value = this._scale + factor;

    if (value <= this._options.scale) {
      this._scale = this._options.scale;

      this._origin = getElementCenter(this.mapRect);

      this._updateTransformOrigin();

      this._setInitialMapPosition();
    } else {
      this._scale += factor;
    }

    this._toggleZoomOutBtn();

    this._updateMapTransform();
  }

  _updateMapTransform() {
    this._svgMap.style.transform = `matrix(${this._scale}, 0, 0, ${this._scale}, ${this._x}, ${this._y})`;
  }

  _updateTransformOrigin() {
    this._svgMap.style.transformOrigin = `${this._origin.x}px ${this._origin.y}px`;
  }

  _toggleZoomOutBtn() {
    if (this._scale === this._options.scale) {
      this._zoomOutBtn.setAttribute('disabled', true);
    } else {
      this._zoomOutBtn.removeAttribute('disabled');
    }
  }

  _calculateOrigin(point) {
    const rect = this.mapRect;

    const position = {
      x: (point.x - rect.x) / this._scale,
      y: (point.y - rect.y) / this._scale,
    };

    const dx = (position.x - this._origin.x) / this._scale;
    const dy = (position.y - this._origin.y) / this._scale;

    const origin = {
      x: this._origin.x + dx,
      y: this._origin.y + dy,
    };

    return origin;
  }

  _showTooltip(unit) {
    this._tooltip.innerHTML = `<strong>${unit.title}</strong>
    <div>${unit.tooltip || ''}</div>`;

    Manipulator.addClass(this._tooltip, CLASSNAME_SHOW);

    EventHandler.on(unit.element, 'mousemove', ({ clientX, clientY }) => {
      this._virtualElement.getBoundingClientRect = this._generateGetBoundingClientRect(
        clientX,
        clientY
      );
      this._virtualElement.contextElement = unit.element;
      this._popper.update();
    });
  }

  _hideTooltip() {
    Manipulator.removeClass(this._tooltip, CLASSNAME_SHOW);
  }

  _generateGetBoundingClientRect(x = 0, y = 0) {
    return () => ({
      width: 0,
      height: 0,
      top: y + 20,
      right: x,
      bottom: y + 20,
      left: x,
    });
  }

  _removeEventHandlers() {
    this._mapUnits.forEach((unit) => {
      EventHandler.off(unit.element, 'click');

      if (this.hoverEvents) {
        EventHandler.off(unit.element, 'mouseover');
        EventHandler.off(unit.element, 'mouseout');
      }
    });

    // Dragging
    EventHandler.off(this._svgMap, 'mousedown', this._mousedownHandler);
    EventHandler.off(window, 'mousemove', this._mousemoveHandler);
    EventHandler.off(window, 'mouseup', this._mouseupHandler);

    // Dragging - touch
    EventHandler.off(this._svgMap, 'touchstart', this._mousedownHandler);
    window.removeEventListener('touchmove', this._mousemoveHandler, { passive: false });
    EventHandler.off(window, 'touchend', this._mouseupHandler);
  }

  // Static
  static get NAME() {
    return NAME;
  }

  static getInstance(element) {
    return Data.getData(element, DATA_KEY);
  }

  static jQueryInterface(config, param1) {
    return this.each(function () {
      let data = Data.getData(this, DATA_KEY);
      const _config = typeof config === 'object' && config;

      if (!data && /dispose/.test(config)) {
        return;
      }

      if (!data) {
        data = new VectorMap(this, _config, param1);
      }

      if (typeof config === 'string') {
        if (typeof data[config] === 'undefined') {
          throw new TypeError(`No method named "${config}"`);
        }

        data[config](param1);
      }
    });
  }
}

// Auto init

SelectorEngine.find(SELECTOR_VECTOR_MAP).forEach((map) => {
  let instance = VectorMap.getInstance(map);

  if (!instance) {
    instance = new VectorMap(map);
  }

  return instance;
});

const $ = getjQuery();

if ($) {
  const JQUERY_NO_CONFLICT = $.fn[NAME];
  $.fn[NAME] = VectorMap.jQueryInterface;
  $.fn[NAME].Constructor = VectorMap;
  $.fn[NAME].noConflict = () => {
    $.fn[NAME] = JQUERY_NO_CONFLICT;
    return VectorMap.jQueryInterface;
  };
}

export default VectorMap;
